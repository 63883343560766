import React from 'react';
import { graphql } from "gatsby"
import SEO from '../components/SEO'
// import { WrapperContext } from '../components/Layout'

const PageMatterport = (props) => {
    // console.log(props)
    const { page } = props.data
    // const _WrapperContext = useContext(WrapperContext)
    // console.log(_WrapperContext)
    const iframeUrl = "https://mpembed.com/show/?m=mjCnkrDD2SN&bgmusic=https://soundcloud.com/gerbi4/cosmosvinitskytlv&bgmusicloop=1";
    return(
        <div className="matterport">
            <SEO
                pageTitle={page.data.title.text}
                pageDescription={page.data.description.text}
                template="template-matterport"
                page
                // pathname={location.pathname} 
            /> 
            <div className="iframe-wrapper">
                <iframe src={iframeUrl}
                    frameBorder="0" 
                    allow='vr' 
                    allowFullScreen='allowfullscreen'
                    width="100%" 
                    height="100%"
                    loading="lazy">
                </iframe>
            </div>
            <div className="footer">
                <ul className="links x xje">
                    {page.data.links.map((link,i) => (
                        <li key={i}>
                            <a href={link.url.url} target="_blank">{link.label.text}</a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default PageMatterport;

export const pageQuery = graphql `
  query PageBySlug($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      data{
        title{text}
        description{text}
        links {
            label {
                text
            }
            url {
                url
                target
            }
        }
      }
    }
  }
`